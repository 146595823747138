<script >
import LegalPersonsEntityDetails
  from "@/views/home/services/registry/business/files/tabs/company_data/LegalPersonsEntityDetails.vue";

export default {
  name: "ChangeOfNomineeDirector",
  components: {
    LegalPersonsEntityDetails,
  },
  props:{
    data:Object,
    brn:String
  },
  mounted() {
    this.fetchCompanyAddress(this.brn)
  },
  methods:{

  },
  computed:{

  }
};
</script>

<template>
<div>
  <b-row class="py-2">
    <b-col>

      <b-row>
        <b-col>
          <b-badge class="bg-blue">
            <i class="fa fa-file mr-2"></i>
            Change of Nominee Director
          </b-badge>
        </b-col>
      </b-row>

      <b-row>
        <b-col>

          <div>

            <div class="mb-3 mt-3" v-if="data &&data.nomineeDirector">
              <div class="border rounded-md border-gray-200">
                <div class="bg-gray-pale">
                  <p class="text-gray mb-2 ml-2 font-bold text-sm p-1">New Nominee Director</p>
                </div>
                <div class="p-2">

                  <legal-persons-entity-details
                      class="p-1"
                      :persons="[{entityType:'INDIVIDUAL', individual:data.nomineeDirector}]"></legal-persons-entity-details>

                </div>
              </div>
            </div>


            <div class="mb-3 mt-3" v-if="data && data.alternativeNomineeDirector">
              <div class="border rounded-md border-gray-200">
                <div class="bg-gray-pale">
                  <p class="text-gray mb-2 ml-2 font-bold text-sm p-1">New Alternative Nominee Director</p>
                </div>
                <div class="p-2">


                  <legal-persons-entity-details class="p-1" :persons="[{entityType:'INDIVIDUAL', individual:data.alternativeNomineeDirector}]"></legal-persons-entity-details>

                </div>
              </div>
            </div>


            <div class="mb-3 mt-3" v-if="data &&data.removedNomineeDirector">
              <div class="border rounded-md border-gray-200">
                <div class="bg-gray-pale">
                  <p class="text-gray mb-2 ml-2 font-bold text-sm p-1">Removed Nominee Director</p>
                </div>
                <div class="p-2">

                  <legal-persons-entity-details
                      class="p-1"
                      :persons="[{entityType:'INDIVIDUAL', individual:data.removedNomineeDirector}]"></legal-persons-entity-details>

                </div>
              </div>
            </div>

            <div class="mb-3 mt-3" v-if="data &&data.removedAlternativeNomineeDirector">
              <div class="border rounded-md border-gray-200">
                <div class="bg-gray-pale">
                  <p class="text-gray mb-2 ml-2 font-bold text-sm p-1">Removed Alternative Nominee Director</p>
                </div>
                <div class="p-2">

                  <legal-persons-entity-details
                      class="p-1"
                      :persons="[{entityType:'INDIVIDUAL', individual:data.removedAlternativeNomineeDirector}]"></legal-persons-entity-details>

                </div>
              </div>
            </div>


          </div>

        </b-col>
      </b-row>

    </b-col>
  </b-row>
</div>
</template>

<style scoped>

</style>