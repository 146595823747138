<template>
  <b-row>
    <b-col>
      <b-row>
        <b-col>
          <b-textarea ref="text" v-model="description"
                      class="mb-2"
                      rows="4"
                      placeholder="Comments">
          </b-textarea>
          <p v-if="description" class="my-2 text-xs">{{description.length}}/{{maxText}}</p>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="9">
          <b-input-group class="border border-grey rounded-sm">
            <b-form-input
              class="form-text text-sm noFocus border-0 text-gray"
              v-model="search"
              v-on:keyup="onSearch"
              type="text"
              placeholder="Search">
            </b-form-input>
            <b-input-group-prepend
              class="bg-white">
              <i class="fas fa-search mx-3 text-gray hover:text-blue" style="margin-top: 12px;" @click="onSearch"></i>
            </b-input-group-prepend>
          </b-input-group>
        </b-col>
        <b-col cols="3">
          <b-form-select v-model="limit" class="w-full form-select paginate-details" v-on:change="fetch">
            <b-select-option value="15">15</b-select-option>
            <b-select-option value="20">20</b-select-option>
            <b-select-option value="50">50</b-select-option>
            <b-select-option value="100">100</b-select-option>
          </b-form-select>
        </b-col>
      </b-row>
      <b-row class="mt-2">
        <b-col cols="12">
          <b-row>
            <b-col>
              <b-progress value="100" v-if="loading"  variant="#007ac5" height="0.2rem" animated></b-progress>
            </b-col>
          </b-row>
          <b-row class="flex py-2 px-3 border-b border-header_grey hover:bg-header_grey" v-for="(registrar, idx) in getRegistrars" :key="idx">
            <b-col cols="9">
              <div class="flex">
                <b-col class="ml-3">
                  <b-row>
                    <b-col>
                      <p class="text-sm text-uppercase" v-if="registrar">
                        <span v-if="registrar.givenName">{{registrar.givenName}}</span>
                        <span class="mx-1" v-if="registrar.surName">{{registrar.surName}}</span>
                        <span class="mx-1" v-if="registrar.middleName">{{registrar.middleName}}</span>
                      </p>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col>
                      <p class="text-xs text-lowercase text-gray italic" v-if="registrar">{{registrar.email}}</p>
                    </b-col>
                  </b-row>
                </b-col>
              </div>
            </b-col>
            <b-col cols="3">
              <button @click="escalate(registrar)" class="button">
                Select <i class="text-white fa fa-check-circle mx-1"></i>
                <b-spinner class="ml-1" small v-if="loadingEscalate&& selectedId === registrar.id"></b-spinner>
              </button>
            </b-col>
          </b-row>
          <b-row class="flex py-1 px-2 bg-header_grey"
                 v-if="getRegistrars.length === 0">
            <b-col cols="12" class="text-center">
              <p class="text-xs">No Account Found</p>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </b-col>
  </b-row>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import jwtService from "@/app/services/jwt.service";

export default {
  name: "Escalation",
  props:{taskId:String, type:String, escalationId:String, permission:String},
  data(){
    return{
      search:null,
      loading:false,
      loadingEscalate:false,
      selectedId:null,
      description:null,
      userProfile:{},
      limit:15,
      maxText:500,
      fromUserId:null,
    }
  },
  mounted() {
    this.fetch()
    this.userProfile = jwtService.getUser()
    this.fromUserId = this.userProfile.userId
  },
  methods:{
    ...mapActions(["fetchRegistrars", "searchRegistrars", "escalateReservationTask", "reEscalateReservationTask",
      "escalateCITask", "escalateCFTask", "escalateAmendmentTask", "escalateBNRTask", "reEscalateBNRTask", "escalateBSITask",
      "escalateLegalDocumentTask", "escalateContinuousFilingTask", "escalateCertificationTask", "reEscalateCertificationTask",
      "escalateDispensationTask", "escalateAnnualReturnsTask","escalateDisputeResolutionTask","escalateInsolvencyTask","escalateDataCorrectionTask"]),
    fetch(){
      this.loading = true
      this.fetchRegistrars({permission:this.permission, limit:this.limit})
    },
    onSearch(){
      this.loading = true
      this.searchRegistrars({ term: this.search, permission: this.permission })
    },
    escalate(person){
      this.selectedId = person.id
      if(this.description != null){
        if(this.userProfile.id !== person.id){
          if(this.type === "reservation"){
            let data = {
              reservationId:this.taskId,
              description:this.description,
              toUserId:person.id
            }
            this.loading = true
            this.loadingEscalate = true
            this.escalateReservationTask(data)
          }

          if(this.type === "reservation-re"){
            let data = {
              escalationId:this.taskId,
              description:this.description,
              toUserId:person.id
            }
            this.loading = true
            this.loadingEscalate = true
            this.reEscalateReservationTask(data)
          }
          if(this.type === "bnr"){
            let data = {
              businessId:this.taskId,
              description:this.description,
              toUserId:person.id
            }
            this.loading = true
            this.loadingEscalate = true
            this.escalateBNRTask(data)
          }
          if(this.type === "bnr-re"){
            let data = {
              escalationId:this.taskId,
              description:this.description,
              toUserId:person.id
            }
            this.loading = true
            this.loadingEscalate = true
            this.reEscalateBNRTask(data)
          }
          if(this.type === "amendment"){
            let data = {
              filingApplicationId:this.taskId,
              description:this.description,
              toUserId:person.id
            }
            this.loading = true
            this.loadingEscalate = true
            this.escalateAmendmentTask(data)
          }
          if(this.type === "amendment-re"){
            let data = {
              filingApplicationId:this.taskId,
              description:this.description,
              toUserId:person.id
            }
            this.loading = true
            this.loadingEscalate = true
            this.reEscalateAmendmentTask(data)
          }
          if(this.type === "ci"){
            let data = {
              taskId:this.taskId,
              comment:this.description,
              registrarId:person.id
            }
            this.loading = true
            this.loadingEscalate = true
            this.escalateCITask(data)
          }
          if(this.type === "dispensation"){
            let data = {
              taskId: this.taskId,
              comment: this.description,
              registrarId: person.id
            }
            this.loading = true
            this.loadingEscalate = true
            this.escalateDispensationTask(data)
          }
          if(this.type === "cf"){
            let data = {
              taskId:this.taskId,
              comment:this.description,
              registrarId:person.id
            }
            this.loading = true
            this.loadingEscalate = true
            this.escalateCFTask(data)
          }

          if(this.type === "annual-returns"){
            let data = {
              taskId:this.taskId,
              comment:this.description,
              registrarId:person.id
            }
            this.loading = true
            this.loadingEscalate = true
            this.escalateAnnualReturnsTask(data)
          }
          if(this.type === "amendment"){
            let data = {
              taskId:this.taskId,
              comment:this.description,
              registrarId:person.id
            }
            this.loading = true
            this.loadingEscalate = true
            this.escalateAmendmentTask(data)
          }
          if(this.type === "bsi"){
            let data = {
              taskId:this.taskId,
              comment:this.description,
              registrarId:person.id
            }
            this.loading = true
            this.loadingEscalate = true
            this.escalateBSITask(data)
          }
          if(this.type === "ld"){
            let data = {
              documentId:this.taskId,
              description:this.description,
              toUserId:person.id
            }
            this.loading = true
            this.loadingEscalate = true
            this.escalateLegalDocumentTask(data)
          }
          if(this.type === "certification"){
            let data = {
              taskId:this.taskId,
              description:this.description,
              toUserId:person.id,
              regEscalatingId:this.fromUserId
            }
            this.loading = true
            this.loadingEscalate = true
            this.escalateCertificationTask(data)
          }
          if(this.type === "certification-re"){
            let data = {
              escalationId:this.taskId,
              description:this.description,
              fromUserId:this.fromUserId,
              toUserId:person.id,

            }
            this.loading = true
            this.loadingEscalate = true
            this.reEscalateCertificationTask(data)
          }

            if(this.type === "disputes"){
                let data = {
                    taskId:this.taskId,
                    comment:this.description,
                    registrarId:person.id
                }
                this.loading = true
                this.loadingEscalate = true
                this.escalateDisputeResolutionTask(data)
            }      if(this.type === "insolvency"){
                let data = {
                    insolvencyProcessId:this.taskId,
                    description:this.description,
                    toUserId:person.id
                }
                this.loading = true
                this.loadingEscalate = true
                this.escalateInsolvencyTask(data)
            }  if (this.type === "data-correction") {
                let data = {
                    taskId:this.taskId,
                    comment: this.description,
                    registrarId:person.id
                };
                this.loading = true;
                this.loadingEscalate = true;
                this.escalateDataCorrectionTask(data);
            }

        }else{
          this.errorToast("Escalation Error", "You cannot escalate to yourself")
        }
      }else{
        this.errorToast("Escalation Error", "Please provide instructions to the next person")
      }
    },
  },
  computed:{
    ...mapGetters(["getRegistrars", "getReservationEscalationSuccess",
      "getEscalationSuccess", "getEscalationError",
      "getAnnualReturnsEscalationSuccess","getInsolvencyEscalation",
      "getDispensationEscalation", "getDispensationEscalationError"]),
  },
  watch:{
    description:{
      handler(data){
        if(data.length >= this.maxText){
          this.errorToast("Text Length Error", "This message is very long")
        }
      },
      deep:true
    },
    getRegistrars(){
      this.loading = false
    },
    getReservationEscalationSuccess(){
      this.loadingEscalate = false
      window.location.reload()
    },
    getDispensationEscalation(){
      this.loadingEscalate = false
      window.location.reload()
    },
    getEscalationSuccess(){
      this.loading = false
      this.loadingEscalate = false
      window.location.reload()
    },
    getAnnualReturnsEscalationSuccess(){
      this.loading = false
      this.loadingEscalate = false
      window.location.reload()
    },
    getEscalationError(data){
      this.loading = false
      this.loadingEscalate = false
      this.errorToast("Escalation Error", data.message)
    },
    getDispensationEscalationError(data){
      this.loading = false
      this.loadingEscalate = false
      this.errorToast("Escalation Error", data.message)
    },
      getInsolvencyEscalation(){
          this.loading = false
          this.loadingEscalate = false
          window.location.reload()
      }
  }
}
</script>

<style scoped>
.button{
  @apply w-full bg-blue mt-1 px-2 py-1 rounded-sm text-white text-xs
}

.button:hover{
  @apply bg-green-dark;
}
</style>