<template>
  <div class="bg-white rounded-sm m-2">
    <b-row>
      <b-modal title="Application Details" ref="applicationDetails" size="xl" hide-footer>
        <WindingInsolvencyDetails :tracking-number="trackingNumber"/>
      </b-modal>
    </b-row>
    <b-row>
      <b-col cols="12" class="mx-3 mt-3">
        <b-row>
          <span class="title">Company Insolvency Applications</span>
        </b-row>
      </b-col>
    </b-row>
    <b-row class="flex mx-3 mt-3">
      <div class="flex-initial p-3 mx-2 stat-count shadow bg-white rounded-md cursor-pointer" >
        <div class="pre-waiting" @click="selectFilter('DRAFT')" >
          <span class="h3 mx-auto">{{getInsolvencyStatistics.stats.DRAFT}}</span>
        </div>
        <div class="pre-waiting">
          <span class="text-sm mx-auto">Drafts</span>
        </div>
      </div>
      <div class="flex-initial p-3 mx-2 stat-count shadow bg-white rounded-md" @click="selectFilter()" >
        <div class="waiting">
          <span class="h3 mx-auto">{{getInsolvencyStatistics.stats.SUBMITTED}}</span>
        </div>
        <div class="waiting">
          <span class="text-sm mx-auto">In Queue</span>
        </div>
      </div>
      <div class="flex-initial p-3 mx-2 stat-count shadow bg-white rounded-md" @click="selectFilter()" >
        <div class="normal">
          <span class="h3 mx-auto">{{getInsolvencyStatistics.stats.ASSIGNED}}</span>
        </div>
        <div class="normal">
          <span class="text-sm mx-auto">Assigned</span>
        </div>
      </div>
      <div class="flex-initial p-3 mx-2 stat-count shadow bg-white rounded-md" @click="selectFilter()" >
        <div class="off-normal">
          <span class="h3 mx-auto">{{getInsolvencyStatistics.stats.ESCALATED}}</span>
        </div>
        <div class="off-normal">
          <span class="text-sm mx-auto">Escalated</span>
        </div>
      </div>
      <div class="flex-initial p-3 mx-2 stat-count shadow bg-white rounded-md" @click="selectFilter()" >
        <div class="warning">
          <span class="h3 mx-auto">{{getInsolvencyStatistics.stats.QUERIED}}</span>
        </div>
        <div class="warning">
          <span class="text-sm mx-auto">Queried</span>
        </div>
      </div>
      <div class="flex-initial p-3 mx-2 stat-count shadow bg-white rounded-md" @click="selectFilter()" >
        <div class="positive">
          <span class="h3 mx-auto">{{getInsolvencyStatistics.stats.APPROVED}}</span>
        </div>
        <div class="positive">
          <span class="text-sm mx-auto">Approved</span>
        </div>
      </div>
      <div class="flex-initial p-3 mx-2 stat-count shadow bg-white rounded-md" @click="selectFilter()" >
        <div class="all">
          <span class="h3 mx-auto">{{getInsolvencyStatistics.REJECTED}}</span>
        </div>
        <div class="all">
          <span class="text-sm mx-auto">Rejected</span>
        </div>
      </div>
    </b-row>

      <b-row class="flex mx-3 mt-3">
          <div class="flex-initial p-3 mx-2 stat-count shadow bg-white rounded-md" @click="selectFilter()" >
              <div class="pre-waiting">
                  <span class="h3 mx-auto">{{getInsolvencyStatistics.stats.PRELIMINARY_REPORT}}</span>
              </div>
              <div class="pre-waiting">
                  <span class="text-sm mx-auto">Prelim Report</span>
              </div>
          </div>
          <div class="flex-initial p-3 mx-2 stat-count shadow bg-white rounded-md" @click="selectFilter()" >
              <div class="waiting">
                  <span class="h3 mx-auto">{{getInsolvencyStatistics.stats.GAZETTE_OR_NEWSPAPER_NOTICES}}</span>
              </div>
              <div class="waiting">
                  <span class="text-sm mx-auto">Notice</span>
              </div>
          </div>
          <div class="flex-initial p-3 mx-2 stat-count shadow bg-white rounded-md" @click="selectFilter()" >
              <div class="positive">
                  <span class="h3 mx-auto">{{getInsolvencyStatistics.stats.FINAL_REPORT}}</span>
              </div>
              <div class="positive">
                  <span class="text-sm mx-auto">Final report</span>
              </div>
          </div>
          <div class="flex-initial p-3 mx-2 stat-count shadow bg-white rounded-md" @click="selectFilter()" >
              <div class="danger">
                  <span class="h3 mx-auto">{{getInsolvencyStatistics.stats.PETITION}}</span>
              </div>
              <div class="danger">
                  <span class="text-sm mx-auto">Petition</span>
              </div>
          </div>
          <div class="flex-initial p-3 mx-2 stat-count shadow bg-white rounded-md" @click="selectFilter()" >
              <div class="waiting">
                  <span class="h3 mx-auto">{{getInsolvencyStatistics.stats.INTERIM_REPORT_NOTICE}}</span>
              </div>
              <div class="waiting">
                  <span class="text-sm mx-auto">Interim Notice</span>
              </div>
          </div>
          <div class="flex-initial p-3 mx-2 stat-count shadow bg-white rounded-md" @click="selectFilter()" >
              <div class="positive">
                  <span class="h3 mx-auto">{{getInsolvencyStatistics.stats.PRELIMINARY_NOTICE}}</span>
              </div>
              <div class="positive">
                  <span class="text-sm mx-auto">Prelim Notice</span>
              </div>
          </div>
          <div class="flex-initial p-3 mx-2 stat-count shadow bg-white rounded-md" @click="selectFilter('INTERIM_REPORT')" >
              <div class="off-normal">
                  <span class="h3 mx-auto">{{getInsolvencyStatistics.stats.INTERIM_REPORT}}</span>
              </div>
              <div class="off-normal">
                  <span class="text-sm mx-auto">Interim Report</span>
              </div>
          </div>
      </b-row>

      <b-row class="flex mx-3 mt-3">

      </b-row>
    <b-row class="flex">
      <b-col cols="12">
        <b-row class="pt-3">
          <b-col>
            <AllInsolvencySubmissions ref="submissions" v-on:openDetails="openDetails"/>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
  </div>
</template>

<script>

import {mapActions, mapGetters} from "vuex";
import AllInsolvencySubmissions from "@/views/home/services/insolvency/submissions/AllInsolvencySubmissions.vue";
import WindingInsolvencyDetails from "@/views/home/services/insolvency/task-queue/WindingInsolvencyDetails.vue";

export default {
  name: "InsolvencyApplications",
  components: {
      WindingInsolvencyDetails,
      AllInsolvencySubmissions

  },
  data(){
    return{
      currentRoute: null,
      trackingNumber:null,
       applications:"winding-up"
    }
  },
  mounted(){
      this.currentRoute = this.$route.fullPath;
    this.fetchInsolvencyStatistics("WINDING_UP")
  },
  methods:{
    ...mapActions(["fetchInsolvencyStatistics"]),
    select(route){
      this.currentRoute = route
    },
    openDetails(trackingNumber){
      this.trackingNumber = trackingNumber
      this.$refs.applicationDetails.show()
    },
      selectFilter(status){
        this.$refs.submissions.fetch(status)
      }
  },
  computed:{
    ...mapGetters(["getInsolvencyStatistics"])
  }
}
</script>

<style scoped>


.pre-waiting{
  @apply flex text-gray_title;
}

.off-normal{
  @apply flex text-blue-light;
}

.waiting{
  @apply flex text-gray;
}

.normal{
  @apply flex text-blue;
}

.positive{
  @apply flex text-green;
}

.warning{
  @apply flex text-orange;
}

.danger{
    @apply flex text-red;
}

.all{
  @apply flex text-flopurple;
}

.stat-count{
  display: inline;
  width: 12%;
  @apply my-1;
}
</style>